import { ApiClient } from "@/services/api/common.api";

export default {
  async requestForSynchronizationWithShelfSystem(scenarioId: string) {
    return ApiClient.post(`/externalSystemsIntegration/request-for-synchronization-with-shelf-system?scenarioId=${scenarioId}`);
  },
  async sendMaterialStockToWms(scenarioId: string) {
    return ApiClient.post(`/externalSystemsIntegration/send-material-stock-to-wms?scenarioId=${scenarioId}`);
  }
};
