import { BaseAuthService, $keycloack, VueKeycloakConfig, VueKeycloakInstance, VueKeycloakOptions } from "@masta/shared";
import { KeycloakError, KeycloakInitOptions, KeycloakInstance, KeycloakLogoutOptions } from "keycloak-js";
import { SignalRHub, useSignalR } from "@/plugins/signalR.plugin";
import { useAuthStore } from "@/store/AuthStore";
import { useScenariosStore } from "@/store/ScenariosStore";
import { useSnackbarsStore } from "@/store/SnackbarsStore";

const keycloakBaseUri = `${import.meta.env.VITE_AUTH_URL}`;

const initializeScenarioState = async () => {
  const store = useScenariosStore();
  await store.fetch();
  const selectedScenarioId = localStorage.getItem("selectedScenarioId");
  if (selectedScenarioId) {
    const scenario = store.scenarios.find((x: any) => x.id === selectedScenarioId);
    if (scenario) {
      store.selectScenario(scenario);
      return;
    }
  }
  const productionScenario = store.scenarios.find((x: any) => x.status === 0);
  if (productionScenario) {
    store.selectScenario(productionScenario);
  }
};

class AuthService extends BaseAuthService {
  private _signalR: SignalRHub | null;

  constructor() {
    super("client");
  }

  public async authenticate(tenantId: string, redirectUri: string): Promise<boolean> {
    if (this.authenticationState.tenantId !== tenantId || this.authenticationState.clientId !== import.meta.env.VITE_AUTH_CLIENT_ID) {
      this._localStorageAuthentication.clear();
      this._localStorageAuthentication.authenticationState.tenantId = tenantId;
      this._localStorageAuthentication.authenticationState.clientId = import.meta.env.VITE_AUTH_CLIENT_ID;
    }
    this._signalR = useSignalR();
    const initializationResult = await $keycloack.initialize({
      init: {
        onLoad: "login-required",
        checkLoginIframe: false,
        token: this._localStorageAuthentication.authenticationState.token,
        refreshToken: this._localStorageAuthentication.authenticationState.refreshToken,
        idToken: this._localStorageAuthentication.authenticationState.idToken,
        redirectUri: `${window.location.origin}${redirectUri}`,
        enableLogging: false
      } as KeycloakInitOptions,
      config: {
        url: keycloakBaseUri,
        clientId: this._localStorageAuthentication.authenticationState.clientId,
        realm: this._localStorageAuthentication.authenticationState.tenantId,
        refreshTokenMinValidity: 60,
        refreshTokenInterval: 10
      } as VueKeycloakConfig,
      logout: {
        redirectUri: `${window.location.origin}`
      } as KeycloakLogoutOptions,
      onReady: this.onReady.bind(this),
      onInitError: this.onInitError.bind(this),
      onInitSuccess: this.onInitSuccess.bind(this),
      onAuthRefreshSuccess: this.onAuthRefreshSuccess.bind(this),
      onAuthRefreshError: this.onAuthRefreshError.bind(this)
    } as VueKeycloakOptions);

    if (initializationResult) {
      const authStore = useAuthStore();
      await Promise.all([authStore.setClientId(import.meta.env.VITE_AUTH_CLIENT_ID), authStore.finishedLoading(), initializeScenarioState()]).finally(async () => {
        await this._signalR?.start({
          tenantId: this.tenantId
        });
      });
    }
    return initializationResult;
  }

  private onInitSuccess(authenticated: boolean, keycloak?: KeycloakInstance, vueKeycloakInstance?: VueKeycloakInstance): void {
    this.updateAuthenticationState(keycloak);
  }

  private onReady(keycloak: KeycloakInstance, vueKeycloakInstance?: VueKeycloakInstance): void {
    this.updateAuthenticationState(keycloak);
  }

  private onInitError(error: Error, keycloakError: KeycloakError): void {
    console.error(error);
    const snackbarsStore = useSnackbarsStore();
    snackbarsStore.createSnackbar({
      message: `Auth error: ${keycloakError.error} | ${keycloakError.error_description}`,
      type: "error",
      closeable: true
    });
  }

  private onAuthRefreshSuccess(keycloak?: KeycloakInstance, vueKeycloakInstance?: VueKeycloakInstance): void {
    this.updateAuthenticationState(keycloak);
  }

  private onAuthRefreshError(keycloak?: KeycloakInstance, vueKeycloakInstance?: VueKeycloakInstance): void {
    this.updateAuthenticationState(keycloak);
  }
}

export const $authService = new AuthService();

