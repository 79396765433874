import { ApiClient } from "@/services/api/common.api";
import {
  CreateResourceAssignmentCommand,
  GetResourceAssignmentsPaginatedQuery,
  GetResourceAssignmentsQuery,
  IAgGridPaginationResponse,
  ResourceAssignmentsResponse,
  ResourceAssignmentType,
  ResourceInfoDto
} from "@masta/generated-model";

export default {
  async getPaginated(request: GetResourceAssignmentsPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<ResourceInfoDto>>(`/resourceassignments/resources`, request);
  },
  async getAssigned(request: GetResourceAssignmentsQuery) {
    return ApiClient.post<ResourceAssignmentsResponse>(`/resourceassignments/assigned`, request);
  },
  async createResourceAssignment(request: CreateResourceAssignmentCommand) {
    return ApiClient.post(`/resourceassignments`, request);
  },
  async deleteResourceAssignment(request: { resource1: string; resource2: string; scenarioId: string; assignmentType: ResourceAssignmentType }) {
    return ApiClient.delete(
      `/resourceassignments?resource1=${request.resource1}&resource2=${request.resource2}&scenarioId=${request.scenarioId}&assignmentType=${request.assignmentType}`
    );
  }
};
