import { useLocalStorage, usePreferredColorScheme } from "@vueuse/core";
import { computed, getCurrentInstance, ref, watch } from "vue";
import { useTheme } from "vuetify";

const defaultTheme = "light";
export type AppColorTheme = "light" | "dark";

export function useAppTheme() {

  const preferredColorScheme = usePreferredColorScheme();
  const browserColorScheme = computed<AppColorTheme>(() => {
    if (preferredColorScheme.value === "no-preference") {
      return defaultTheme;
    } else {
      return preferredColorScheme.value;
    }
  });

  const theme = useLocalStorage<AppColorTheme>("theme", browserColorScheme.value);

  return theme;
}

export function useAppThemeWithVuetifyTheme() {
  if (!getCurrentInstance()) {
    throw new Error("useAppThemeWithVuetifyTheme() must be used within the setup function.");
  }
  const vuetifyTheme = useTheme();
  const theme = useAppTheme();

  watch(theme, (value) => {
    if (vuetifyTheme.global.name.value !== value)
      vuetifyTheme.global.name.value = value;
  });
}
